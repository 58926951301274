import React from 'react';
import fbLogo from '../../Images/fbLogo.png'
import fbHome from '../../Images/fbHome.png'
import people from '../../Images/people.png'
import video from '../../Images/video.png'
import marketplace from '../../Images/marketplace.png'
import notification from '../../Images/notification.png'

const FacebookNavbar = () => (
  <nav style={styles.navbar}>
    <div style={styles.logoSection}>
      <div style={styles.logo}><img src={fbLogo} alt='fb' /></div>
      <input style={styles.searchInput} type="text" placeholder="Search Facebook" />
    </div>
    <div style={styles.menuSection}>
      <div style={styles.menuItem}><img src={fbHome} alt='home-icon' /> </div>
      <div style={styles.menuItem}><img src={people} alt='home-icon' /></div>
      <div style={styles.menuItem}><img style={{height:'26px'}} src={marketplace} alt='home-icon' /></div>
      <div style={styles.menuItem}><img style={{height:'26px'}} src={notification} alt='home-icon' /></div>
      {/* <div style={styles.menuItem}></div> */}
    </div>
    <div style={styles.profileSection}>
      <div style={styles.notification}>20+</div>
      <div style={styles.profilePic}><img style={{height:'30px',borderRadius:'50%'}} src='https://bit.ly/kent-c-dodds' alt='pp' /></div>
    </div>
  </nav>
);

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '10px 20px',
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginRight: '10px',
  },
  searchInput: {
    border: '1px solid #ddd',
    borderRadius: '20px',
    padding: '5px 15px',
    fontSize: '14px',
  },
  menuSection: {
    display: 'flex',
  },
  menuItem: {
    width: '30px',
    height: '30px',
    marginLeft: '10px',
    // backgroundColor: '#ddd',
    borderRadius: '50%',
    display:'flex',
    justifyContent:"center",
    alignItems: 'center',
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
  },
  notification: {
    marginRight: '5px',
    backgroundColor: 'red',
    color: '#fff',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '12px',
    marginLeft:'-18px'
  },
  profilePic: {
    width: '30px',
    height: '30px',
    backgroundColor: '#ddd',
    borderRadius: '50%',
  }
};

export default FacebookNavbar;
