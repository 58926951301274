import React from 'react';
import Card from './Card';
import useMediaQuery from '../Utils/useMediaQuery';
import GoogleAdsImage from '../Images/GoogleLogo.png'
import FacebookAdsImage from '../Images/FacebookAds.png'
import EmailAdsImage from '../Images/EmailAds.png'
import { Link } from 'react-router-dom';
import InstagramImage from '../Images/instagram.jpeg'
import Aff1 from '../Images/aff1.jpg'
import Aff2 from '../Images/aff2.jpg'
import Aff3 from '../Images/aff3.jpg'
import CashKaro from '../Images/cklogo.png'
import xyz from '../Images/xyz.png'
import EarnKaro from '../Images/eklogo.png'
import BingLogo from '../Images/bingLogo.webp'


const GridIdle = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  const isMediumScreen = useMediaQuery('(max-width: 768px)');
  const isSmallScreen = useMediaQuery('(max-width: 480px)');

  let columns = 'repeat(3, 1fr)'; 

  if (isMediumScreen) {
    columns = 'repeat(2, 1fr)'; 
  }

  if (isSmallScreen) {
    columns = 'repeat(1, 1fr)'; 
  }

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: columns, 
    gap: '20px',
    justifyContent: 'space-around',
   
  };

  const cardData=[
    {id:1,name:'Google',image:GoogleAdsImage,url:'/ideal-search'},
    {id:2,name:'Facebook Ads',image:FacebookAdsImage,url:'/facebook-ideal'},
    {id:3,name:'Instagram Ads',image:InstagramImage,url:'/insta-ideal'},
    {id:4,name:'Affiliate 1',image:Aff1,url:'https://secure.traqkarr.com/click?pid=210&offer_id=1356&sub2=test&sub1=Affiliate_CPS&sub7=ideal'},
    {id:5,name:'Affiliate 2',image:Aff2,url:'https://secure.traqkarr.com/click?pid=211&offer_id=1356&sub2=test&sub1=Affiliate_CPS&sub7=ideal'},
    {id:6,name:'Affiliate 3',image:Aff3,url:'https://secure.traqkarr.com/click?pid=212&offer_id=1356&sub2=test&sub1=Affiliate_CPS&sub7=ideal'},
    {id:7,name:'CashKaro',image:CashKaro,url:'https://secure.traqkarr.com/click?pid=3&offer_id=1356&sub2=diksha&sub1=PaidAd_CPC&sub7=ideal'},
    {id:8,name:'EarnKaro',image:EarnKaro,url:'https://secure.traqkarr.com/click?pid=4&offer_id=1356&sub2=Test&sub1=Affiliate_CPS&sub7=ideal'},
    {id:9,name:'Microsoft Ads',image:BingLogo,url:'https://secure.traqkarr.com/click?pid=213&offer_id=1356&sub2=bing&sub1=PaidAd_CPC&sub7=ideal'},

  ]

  return (
    <div style={{textAlign:'center'}}>
        <h1>Ideal Setup</h1>
        <div style={containerStyle}>
      <div style={gridContainerStyle}>
        {cardData.map((item)=><Link target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}} to={item.url} key={item.id}><Card image={item.image} name={item.name}  /></Link>)}
      </div>
    </div>
    </div>
  );
};

export default GridIdle;
