import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Grid from '../Components/Grid';
import GridIdle from '../Components/GridIdle';
import './Home.css'; // Import your stylesheet

const Home = () => {
  const [activeTab, setActiveTab] = useState('grid');

  return (
    <>
      <Navbar />

      <div className="tab-navigation">
        <button 
          className={activeTab === 'grid' ? 'active' : ''} 
          onClick={() => setActiveTab('grid')}>
          Generic Setup
        </button>
        <button 
          className={activeTab === 'gridIdle' ? 'active' : ''} 
          onClick={() => setActiveTab('gridIdle')}>
          Ideal Setup
        </button>
      </div>

      {activeTab === 'grid' && <Grid />}
      {activeTab === 'gridIdle' && <GridIdle />}
    </>
  );
}

export default Home;
