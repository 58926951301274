import React from 'react';

const PromotionCard = () => (
  <div style={styles.card}>
    <div style={styles.content}>
      <div style={styles.title}>
        <span role="img" aria-label="party popper">🎉</span> 
        New Year Exclusive Offer
      </div>
      <button onClick={()=>window.open('https://secure.traqkarr.com/click?pid=217&offer_id=1057&sub2=Test&sub1=PaidAd_CPC&sub7=generic')} style={styles.button}>Get Offer</button>
    </div>
    <div style={styles.footer}>
      <a target="_blank" rel="noopener noreferrer" href="https://secure.traqkarr.com/click?pid=217&offer_id=1057&sub2=Test&sub1=PaidAd_CPC&sub7=generic" style={styles.link}>https://secure.traqkarr.com/</a>
      <div style={styles.shareButton}>Share</div>
    </div>
  </div>
);

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '600px', // Adjust width as necessary
    backgroundColor: '#f9f9f9'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#333',
    maxWidth: '70%',
  },
  button: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    fontSize: '14px',
  },
  shareButton: {
    fontSize: '14px',
    color: '#007bff',
    cursor: 'pointer',
  }
};

export default PromotionCard;
