import React from 'react';
import Drops from '../Images/drops.svg';

const Navbar = () => {
  const backgroundStyle = {
    backgroundColor: '#2f3c97',
    height: '120px',
    backgroundImage: `url(${Drops})`, 
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', 
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:'20px',
    width:'100%'
  };

  return <div style={backgroundStyle}>
    <h1><span style={{color:'#ee742f'}}>Traq</span><span style={{color:'#fff'}}>Kar</span></h1>
  </div>;
};

export default Navbar;
