import React from 'react';
import InstagramAd from './InstagramAd';
import heart from '../../Images/heart.png'
import chat from '../../Images/chat.png'
import send from '../../Images/send.png'
import save from '../../Images/save.png'
import tkIcon from '../../Images/TestKaroFavicon.png'
import vacation from '../../Images/vacation.jpeg'

const InstagramPost = () => {
  return (
   <>
    <div className="instagram-post">
      <div className="post-header">
        <div className="user-info">
        <div className="profile-pic"><img src={tkIcon} alt='tk icon' /></div>
          <div className="user-details">
            <div className="username">Cashtraq</div>
            <div className="location">Sponsored</div>
          </div>
        </div>
        <div className="more-options">⋮</div>
      </div>
      <div className="post-image">
        <img src="https://cashtraq.in/static/media/bestSellerTwo.c2641a76c189fcc71670.webp" alt="Post" />
      </div>
      <div className="post-actions">
        <div><img src={heart} alt='heart' /></div>
        <div><img src={chat}  alt='comment'/></div>
        <div><img src={send} alt='share' /></div>
        <div><img src={save} alt='save' /></div>
      </div>
      <div className="post-likes">179 Likes</div>
      <div className="post-caption">
        <span className="username">Cashtraq</span>
        <span>Upgrade Your Style with Our Stylish Bag Collection!</span>
      </div>
      <div className="post-timestamp">1 Day Ago</div>
      <InstagramAd />

      <style jsx>{`
        .instagram-post {
          max-width: 30%;
          margin: auto;
          background: #fff;
          border: 1px solid #dbdbdb;
          border-radius: 3px;
          border-top:none;
        }
        .post-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
        }
        .user-info {
          display: flex;
          align-items: center;
        }
        .profile-pic {
          background-color: #ff4500;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          margin-right: 10px;
        }
        .user-details .username {
          font-weight: bold;
        }
        .user-details .location {
          font-size: 0.8rem;
          color: #555;
        }
        .more-options {
          font-size: 24px;
        }
        .post-image img {
          width: 100%;
          height: auto;
        }
        .post-actions {
          display: flex;
          justify-content: space-around;
          padding: 10px 0;
        }
        .post-actions div {
          font-size: 24px;
        }
        .post-likes, .post-caption, .post-timestamp {
          padding: 0 10px;
        }
        .post-caption {
          display: flex;
          gap: 5px;
        }
        .post-caption .username {
          font-weight: bold;
        }
        .post-timestamp {
          font-size: 0.8rem;
          color: #555;
          padding-bottom: 10px;
        }
      `}</style>
    </div>
    <div className="instagram-post">
      <div className="post-header">
        <div className="user-info">
          <div className="profile-pic">M</div>
          <div className="user-details">
            <div className="username">Manik Gupta</div>
            <div className="location">Shimla</div>
          </div>
        </div>
        <div className="more-options">⋮</div>
      </div>
      <div className="post-image">
        <img src={vacation} alt="Post" />
      </div>
      <div className="post-actions">
      <div><img src={heart} alt='heart' /></div>
        <div><img src={chat}  alt='comment'/></div>
        <div><img src={send} alt='share' /></div>
        <div><img src={save} alt='save' /></div>
      </div>
      <div className="post-likes">92 Likes</div>
      <div className="post-caption">
        <span className="username">Manik Gupta</span>
        <span>Vacation</span>
      </div>
      <div className="post-timestamp">1 Day Ago</div></div>
   </>
  );
}

export default InstagramPost;
