import React from 'react';
import FacebookNavbar from './FacebookNavbar';
import PromotionCard from './PromotionCard';
import tkIcon from '../../Images/TestKaroFavicon.png'
import vacation from '../../Images/vacation.jpeg'

const FacebookIdeal = () => (
 <div style={{display:'flex',justifyContent:'center'}}> 
     <div style={styles.container}>
 <FacebookNavbar />
    <div style={styles.header}>
      <div style={styles.profilePicPlaceholder}>
       <img src={tkIcon} alt='pp' /> 
      </div>
      <div style={styles.headerText}>
        <div style={styles.title}>Cashtraq</div>
        <div style={styles.subtitle}>Sponsored</div>
      </div>
    </div>
    <div style={styles.postContent}>
      <p>
        <span role="img" aria-label="party popper">🎉</span> 
        Upgrade Your Style with Our Stylish Bag Collection! 💼✨ Shop Now and Elevate Your Look to New Heights! #FashionForward #MustHaveBag
      </p>
      <p>
        Utilize code “NY50” to capture this electrifying, time-limited deal!
      </p>
      <img style={{height:'360px',width:'500px'}} src='https://cashtraq.in/static/media/bestSellerTwo.c2641a76c189fcc71670.webp' alt='post' />
      <PromotionCard />
    </div>
    <div style={styles.header}>
      <div style={styles.profilePicPlaceholder}><img style={{borderRadius:'50%'}} src={'https://bit.ly/kent-c-dodds'} alt='pp' /></div>
      <div style={styles.headerText}>
        <div style={styles.title}>Manik Pathak</div>
        {/* <div style={styles.subtitle}>Sponsored</div> */}
      </div>
    </div>
    <div style={styles.postContent}>
      <p>
        <span role="img" aria-label="party popper">🎉</span> 
        Vacation Diaries
      </p>
      <p>
       
      </p>
      <img style={{height:'360px',width:'500px'}}  src={vacation} alt='post' />
      
    </div>
  </div>
 </div>
);

const styles = {
  container: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    margin: '10px',
    maxWidth: '500px', // typical width for mobile
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  profilePicPlaceholder: {
    width: '50px',
    height: '50px',
    backgroundColor: '#ccc',
    borderRadius: '50%',
    marginRight: '10px',
    display:'flex',
    justifyContent: 'center',
  },
  headerText: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '12px',
    color: '#888',
  },
  postContent: {
    fontSize: '14px',
    color: '#333',
    marginBottom:'20px'
  }
};

export default FacebookIdeal;
