import React from 'react'
import InstagramNavbar from './InstagramNavbar'
import InstagramPost from './InstagramPost'

const InstagramIdeal = () => {
  return (
    <div>
        <InstagramNavbar />
        <InstagramPost />
    </div>
  )
}

export default InstagramIdeal