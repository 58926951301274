import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Grid from './Components/Grid';
import GoogleSearch from './Components/GoogleSearch';
import { Route, Routes } from 'react-router';
import Home from './Pages/Home';
import GoogleSearchIdeal from './Components/GoogleSearchIdeal';
import FacebookAd from './Components/FacebookGeneric/FacebookAd';
import FacebookIdeal from './Components/FacebookIdeal/FacebookIdeal';
import InstagramGeneric from './Components/InstagramGeneric/InstagramGeneric';
import InstagramIdeal from './Components/InstagramIdeal/InstagramIdeal';


function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/search-results' element={<GoogleSearch />} />
      <Route path='/ideal-search' element={<GoogleSearchIdeal />}/>
      <Route path='/facebook-generic' element={<FacebookAd />} />
      <Route path='/facebook-ideal' element={<FacebookIdeal />} />
      <Route path='/insta-generic' element={<InstagramGeneric />} />
      <Route path='/insta-ideal' element={<InstagramIdeal />} />
    </Routes>
    
    </>
  );
}

export default App;
