import React, { useState } from 'react';
import './GoogleSearch.css'; // Import a CSS file for styling
import MyntraIcon from '../Images/myntraIcon.jpeg'
import denoIcon from '../Images/denoIcon.png'
import ckIcon from '../Images/ckFav.png'
import tkIcon from '../Images/TestKaroFavicon.png'

const mockSearchResults = [
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=214&offer_id=1057&sub2=test&sub1=PaidAd_CPC&sub7=generic',
    sponsored: true,
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1057&sub2=test&sub1=SearchRank_CPC&sub7=generic',
  },
  {
    mainTitle:'CashKaro',
    title: 'Cashtraq Sales Offers 2023: 80% Off + 6% ...',
    snippet: `Love shopping? Get the best prices on fashion, beauty, accessories, and more with the Cashtraq sale offers 2023. And there's more. With CashKaro, you can not ...`,
    url: `https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal`,
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: 'This is the snippet for result 3.',
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal',
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=214&offer_id=1057&sub2=test&sub1=PaidAd_CPC&sub7=generic',
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1057&sub2=test&sub1=SearchRank_CPC&sub7=generic',
  },
  {
    mainTitle:'CashKaro',
    title: 'Cashtraq Sales Offers 2023: 80% Off + 6% ...',
    snippet: `Love shopping? Get the best prices on fashion, beauty, accessories, and more with the Cashtraq sale offers 2023. And there's more. With CashKaro, you can not ...`,
    url: `https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal`,
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: 'This is the snippet for result 3.',
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal',
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=214&offer_id=1057&sub2=test&sub1=PaidAd_CPC&sub7=generic',
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: `Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products ...`,
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1057&sub2=test&sub1=SearchRank_CPC&sub7=generic',
  },
  {
    mainTitle:'CashKaro',
    title: 'Cashtraq Sales Offers 2023: 80% Off + 6% ...',
    snippet: `Love shopping? Get the best prices on fashion, beauty, accessories, and more with the Cashtraq sale offers 2023. And there's more. With CashKaro, you can not ...`,
    url: `https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal`,
  },
  {
    mainTitle:'Cashtraq',
    title: 'Cashtraq: Online Shopping for Women, Men, Kids Fashion ...',
    snippet: 'This is the snippet for result 3.',
    url: 'https://secure.traqkarr.com/click?pid=215&offer_id=1356&sub2=test&sub1=SearchRank_CPC&sub7=ideal',
  },
];


const SearchResult = ({ mainTitle,title, snippet, url, sponsored }) => (
  <div class="search-result">
    {sponsored && <p style={{marginBottom:'10px',fontSize:'14px',fontWeight:1000}}>Sponsored</p>}
  <div style={{display:'flex',alignItems:'center'}}><img className='favicon' src={mainTitle==='CashKaro'?ckIcon:tkIcon} alt='fav' />{mainTitle}</div>
  <div class="url">{url.split('click')[0]}</div>
  <a target="_blank" rel="noopener noreferrer" href={url} class="title">
      {title}
  </a>
  <p class="description">
    {snippet}
  </p>
</div>
);

const SearchResults = ({ results }) => (
  <div className="search-results">
    {results.map((result, index) => (
      <SearchResult key={index} {...result} />
    ))}
  </div>
);

const GoogleSearch = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(mockSearchResults);

  const handleSearch = () => {
    setSearchResults(mockSearchResults);
  };

  return (
    <div className="google-search">
      <div className="search-bar">
      <img style={{height:'35px'}} src='https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png' alt='google logo' />
        <input
          type="text"
          placeholder="Enter your search query"
          value={'Cashtraq'}
          onChange={(e) => setQuery(e.target.value)}
          style={{border:'1px solid gray'}}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div style={{width:'60%'}}>
      <SearchResults results={searchResults} />
      </div>
    </div>
  );
};

export default GoogleSearch;
