import React from 'react';

const InstagramAd = () => {
  return (
    <div onClick={()=>window.open('https://secure.traqkarr.com/click?pid=216&offer_id=1356&sub2=Test&sub1=PaidAd_CPC&sub7=ideal')} className="instagram-ad-banner">
      <span className="text">Shop Now</span>
      <span className="arrow">{`>`}</span>

      <style jsx>{`
        .instagram-ad-banner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #003569;
          color: white;
          font-size: 18px;
          font-weight: bold;
          margin: 20px 0;
          cursor: pointer;
        }
        .text {
          margin-left: 10px;
        }
        .arrow {
          font-size: 24px;
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
}

export default InstagramAd;
