import React, { useState } from 'react';
import RedirectIcon from '../Images/redirect.png'

const Card = ({ image,name }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const cardStyle = {
    position: 'relative',
    overflow: 'hidden', 
    width: '300px', 
    height: '200px',
    display:'flex',
    justifyContent: 'center'
  };

  const imageStyle = {
    width: 'fit-content', 
    height: '100%', 
    objectFit: 'cover', 
    display: 'block', 
  };

  return (
    <div style={{textAlign:'center'}}>
      <div
        className={`card ${hovered ? 'hovered' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={cardStyle}
      >
        <div className="overlay"></div>
        <img src={image} alt={name} style={imageStyle} />
      </div>
      <p>{name}</p>
    </div>
  );
};

export default Card;
