import React from 'react';
import IgLogo from '../../Images/insta_logo.png'
import igCamera from '../../Images/igCamera.png'
import igDm from '../../Images/igDm.png'

const InstagramNavbar = () => {
  return (
    <div className="instagram-mobile-screen">
      <div className="instagram-navbar">
        <div className="icon camera-icon"><img style={{height:'24px'}} src={igCamera} alt='ig camera'/></div>
        <div className="title"><img style={{height:'30px'}} src={IgLogo} alt='ig logo' /></div>
        <div className="icon arrow-icon"><img style={{height:'24px'}} src={igDm} alt='ig camera'/></div>
      </div>

      <style jsx>{`
        .instagram-mobile-screen {
          max-width: 30%;
          margin: auto;
        }
        .instagram-navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #fff;
          border-bottom: 1px solid #dbdbdb;
        }
        .icon {
          font-size: 24px;
        }
        .camera-icon {
          /* Placeholder for camera icon */
          display:flex;
          justify-content: center;
          align-items: center;
        }
        .title {
          font-size: 20px;
          font-weight: bold;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        .arrow-icon {
          /* Placeholder for arrow icon */
          display:flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
}

export default InstagramNavbar;
